import { orderBy } from "lodash-es";
import { SORT_ASC, SortPayload } from "react-admin";

import { Tracker } from "../../types";

const getSortField = (tracker: Tracker, field: string) => {
  const sortField = tracker[field as keyof Tracker];

  return typeof sortField === "string" ? sortField.toLowerCase() : sortField;
};

export const getSortedTrackersList = (trackers: Tracker[], sort?: SortPayload) => {
  if (!sort) {
    return trackers;
  }

  const { field, order } = sort;

  return orderBy(
    trackers,
    (tracker) => getSortField(tracker, field),
    order === SORT_ASC ? "asc" : "desc"
  );
};
