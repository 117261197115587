import GetAppIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import {
  gridExpandedSortedRowEntriesSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";

import { useCallback } from "react";
import {
  fetchRelatedRecords,
  useDataProvider,
  useListContext,
  useNotify,
  useResourceContext
} from "react-admin";

import { mapColumnsToExport } from "./helpers/mapColumnsToExport";

import { useListDataGridContext } from "../../../../context";
import { toolbarButtonSize } from "../../../ListDataGridToolbar/ListDataGridToolbar.styled";

const EXPORT_MAX_RESULTS = 200;

// Based on the ExportButton component from ReactAdmin
// They do not expose the logic separartely from the component, so we have to copy it here
export const ListDataGridExportButton = () => {
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const apiRef = useGridApiContext();

  const { filter, filterValues, sort, exporter, total } = useListContext();
  const { filtersState } = useListDataGridContext();

  const visibleRows = useGridSelector(apiRef, gridExpandedSortedRowEntriesSelector);

  const handleClick = useCallback(async () => {
    if (!resource) return;

    const data =
      filtersState === "client-side"
        ? visibleRows.map((row) => row.model)
        : (
            await dataProvider.getList(resource, {
              sort,
              filter: filter ? { ...filterValues, ...filter } : filterValues,
              pagination: { page: 1, perPage: EXPORT_MAX_RESULTS }
            })
          ).data;

    if (exporter && data) {
      try {
        exporter(
          mapColumnsToExport(data, apiRef.current.getAllColumns()),
          fetchRelatedRecords(dataProvider),
          dataProvider,
          resource
        );
      } catch (error) {
        console.error(error);
        notify("ra.notification.http_error", { type: "warning" });
      }
    }
  }, [
    apiRef,
    dataProvider,
    exporter,
    filter,
    filterValues,
    filtersState,
    notify,
    resource,
    sort,
    visibleRows
  ]);

  return (
    <IconButton
      onClick={handleClick}
      disabled={total === 0}
      size="small"
      title="Export"
      color="primary"
      sx={{ ...toolbarButtonSize }}
    >
      <GetAppIcon />
    </IconButton>
  );
};
