import { QueryClient } from "@tanstack/react-query";

import * as CONSTS from "../../consts";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: CONSTS.DEFAULT_STALE_TIME
    }
  }
});
