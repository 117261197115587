import { PropsWithChildren } from "react";
import { AppBar, AppBarProps } from "react-admin";
import { useNavigate } from "react-router-dom";

import { Logo } from "../../../Logo";

import * as S from "../../styled";

export const AppTopBar = (props: PropsWithChildren<Pick<AppBarProps, "toolbar" | "userMenu">>) => {
  const navigate = useNavigate();

  const goToMainPage = () => {
    navigate("/");
  };

  return (
    <AppBar
      {...props}
      color="inherit"
      sx={{
        "& .RaAppBar-menuButton": {
          display: "none"
        }
      }}
    >
      <S.TopBarContainer>
        <S.LogoContainer onClick={goToMainPage}>
          <Logo />
        </S.LogoContainer>

        {props.children}
      </S.TopBarContainer>
    </AppBar>
  );
};
