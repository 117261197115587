import { RaRecord, SortPayload, useListContext, useResourceContext } from "react-admin";

import { useServerSideModelProps } from "common";

import { ListDataGridToolbar } from "./components/ListDataGridToolbar";
import { ListDataGridContextProvider } from "./context";
import { DataGrid } from "../DataGrid";
import { DataGridProps } from "../DataGrid/DataGrid";

interface Props<DataType extends RaRecord> extends Omit<DataGridProps<DataType>, "data"> {
  hasCreatePage?: boolean;
  onAddClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  canEdit?: boolean;

  defaultSort?: SortPayload;

  serverSidePagination?: boolean;
  serverSideSort?: boolean;
  serverSideFilters?: boolean;
}

export const ListDataGrid = <DataType extends RaRecord>({
  columnVisibilityModel,
  disableCheckboxSelection,
  handleContextMenu,
  hasCreatePage,
  onAddClick,
  disableColumnFilter,

  canEdit,

  defaultSort,

  serverSideSort,
  serverSidePagination,
  serverSideFilters,

  ...props
}: Props<DataType>) => {
  const {
    onSortModelChange,

    filtersState,
    onFilterModelChange,

    rowCount,
    paginationModel,
    onPaginationModelChange
  } = useServerSideModelProps({
    disableColumnFilter,
    serverSideFilters,
    defaultSort
  });

  const resource = useResourceContext();
  const { data = [], isLoading, isFetching } = useListContext<DataType>();

  return (
    <ListDataGridContextProvider
      hasCreatePage={Boolean(hasCreatePage)}
      onAddClick={onAddClick}
      filtersState={filtersState}
    >
      <DataGrid<DataType>
        data={data}
        Toolbar={ListDataGridToolbar}
        loading={isLoading || isFetching}
        onSortModelChange={serverSideSort ? onSortModelChange : undefined}
        onFilterModelChange={serverSideFilters ? onFilterModelChange : undefined}
        rowCount={serverSidePagination ? rowCount : undefined}
        paginationModel={serverSidePagination ? paginationModel : undefined}
        onPaginationModelChange={serverSidePagination ? onPaginationModelChange : undefined}
        disableCheckboxSelection={!canEdit || disableCheckboxSelection}
        handleContextMenu={canEdit ? handleContextMenu : undefined}
        storageKey={resource}
        {...props}
        initialState={{
          columns: { columnVisibilityModel },
          pagination: {
            paginationModel: {
              pageSize: 20
            }
          },
          ...props.initialState
        }}
      />
    </ListDataGridContextProvider>
  );
};
