import { styled } from "@mui/material/styles";

import * as CONSTS from "../../consts";

export const Container = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${CONSTS.SHOW_MORE_ITEMS_GAP};
  margin-top: 6px;
`;

export const ShowMoreText = styled("span")(
  ({ theme }) => `
  margin-left: ${theme.spacing(0.5)};
`
);
