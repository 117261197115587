import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useCallback } from "react";
import { useTheme } from "react-admin";

import { isThemeType } from "common";

export const ToggleThemeButton = () => {
  const [currentTheme, setCurrentTheme] = useTheme();
  const isDark = isThemeType(currentTheme) && currentTheme === "dark";

  const handleTogglePaletteType = useCallback(() => {
    setCurrentTheme(isDark ? "light" : "dark");
  }, [isDark, setCurrentTheme]);

  return (
    <Tooltip title={isDark ? "Light mode" : "Dark mode"}>
      <IconButton
        onClick={handleTogglePaletteType}
        size="small"
        sx={{
          color: "inherit"
        }}
      >
        {isDark ? (
          <LightModeOutlinedIcon color="action" />
        ) : (
          <DarkModeOutlinedIcon color="action" />
        )}
      </IconButton>
    </Tooltip>
  );
};
