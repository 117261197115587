import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { assertIsAgentInfoData } from "./guards/assertIsAgentInfoData";

import { agentInfo } from "./queryKeys";
import { AgentInfoData } from "./types";
import { httpClient } from "../../httpClient";

const API_URL = process.env.REACT_APP_API_URL;

export const useAgentInfo = (): UseQueryResult<AgentInfoData> =>
  useQuery({
    queryKey: agentInfo(),

    queryFn: () =>
      httpClient(`${API_URL}/info/agent`).then(({ json }) => {
        assertIsAgentInfoData(json);
        return json;
      })
  });
