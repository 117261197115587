import { SortPayload } from "react-admin";

import { Query } from "types";

export const FOREIGN_KEY_SEPARATOR = "." as const;
const ID = "id" as const;

export const applySortingToQuery = <TData>(query: Query<TData>, sort?: SortPayload) => {
  if (!sort) {
    return query;
  }

  const { field, order } = sort;

  if (field === ID) {
    return query;
  }

  if (field.includes(FOREIGN_KEY_SEPARATOR)) {
    const [table, column] = field.split(FOREIGN_KEY_SEPARATOR);

    return query.order(`${table}(${column})`, {
      ascending: order === "ASC",
      nullsFirst: false
    });
  }

  return query.order(field, { ascending: order === "ASC", nullsFirst: false });
};
