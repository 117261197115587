import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { currentUser } from "./queryKeys";
import { getCurrentUser } from "../../../auth";
import { User } from "../../../dataProvider";

export const useCurrentUser = (): UseQueryResult<User> =>
  useQuery({
    queryKey: currentUser(),
    queryFn: () => getCurrentUser()
  });
