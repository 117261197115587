import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

import { ROWS_PER_PAGE } from "consts";

import { mapCommandResponse } from "./helpers/mapCommandResponse";
import { Command } from "./types";
import { httpClient } from "../../services";

import { TRACKERS_FILTER_KEY } from "../const";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "commands";

const COMMANDS_API = `${API_URL}/${RESOURCE_NAME}`;

export const commandsDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const page = params.pagination?.page ?? 1;
    const perPage = params.pagination?.perPage ?? ROWS_PER_PAGE;

    const searchParams = new URLSearchParams({
      skip: ((page - 1) * perPage).toString(),
      limit: perPage.toString(),
      tid: params.filter[TRACKERS_FILTER_KEY]
    });

    const { json, headers } = await httpClient(`${COMMANDS_API}?${searchParams.toString()}`);

    const commands: Command[] = json.map(mapCommandResponse);
    const totalCommands = Number(headers.get("x-total"));

    return {
      data: commands,
      total: totalCommands
    };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const commands: Command[] = await httpClient(COMMANDS_API).then(({ json }) => json);

    return {
      data: commands
    };
  },

  // TODO not implemented yet
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
